<template>
  <div class="error-box no-fav-box">
    <!-- <p>code:{{ code }}</p>
    <p>resData:{{ resData }}</p>
    <p>resError:{{ resError }}</p>
    <p>userData:{{ userData }}</p> -->
    <p v-if="!userList.length && !loading" class="txt">暂无权限进入本系统,请联系系统管理员</p>
    <Nodata v-if="loading" :loading="loading" empty-txt="暂无权限进入本系统,请联系系统管理员" />
  </div>
</template>
<script>
import { getInfoByCode, getUserInfo } from '@/api/user';
import loginMixins from '@/mixins/loginMixins';
import Nodata from '@/components/Nodata';

export default {
  components: {
    Nodata
  },
  mixins: [loginMixins],
  data() {
    return {
      userList: [],
      code: '',
      resData: '',
      userData: '',
      resError: '',
      userError: ''
    };
  },
  mounted() {
    this.getCode();
  },
  methods: {
    getUInfo(userId) {
      getUserInfo(userId).then(res => {
        this.userData = JSON.stringify(res.value);
        if (res && res.value.length) {
          this.loginObj.userName = res.value[0].user_name;
          this.loginObj.userId = res.value[0].user_id;
          this.userList = res.value;
          this.loginFn('oauth2');
        } else {
          this.loading = false;
        }
      }).catch(e => {
        this.userError = JSON.stringify(e);
        this.loading = false;
      });
    },
    getCode() {
      this.userList = [];
      let code = this.$route.query.code;
      // https://hcp360-demo-bsc.lekinsights.cn/api/weixin/login?code=P951Z7szHgoVrjoEgF6FbfPWT0HqPiGZM9UWl-m0jWY
      this.loading = true;
      this.code = code;
      getInfoByCode(code).then(res => {
        this.resData = JSON.stringify(res);
        if (res.userId) {
          this.getUInfo(res.userId);
        } else {
          this.loading = false;
        }
      }).catch(e => {
        this.resError = 'error=' + JSON.stringify(e);
        this.loading = false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/styles/var.scss';
.no-fav-box {
  min-width: 250px;
  padding-top: 60px;
  .txt {
    font-size: 13px;
    color: #707070;
    & + .txt {
      margin-top: 20px;
    }
  }
  .cube-btn {
    // width: 1.44rem;
    padding: 10px 40px;
    font-size: 14px;
    border-radius: 20px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
.radius-btn {
  border-radius: 20px;
}
</style>
