<template>
  <div v-if="false" style="height: 100%;">
    <Loading v-if="loading" :loading="loading" />
  </div>
</template>
<script>
import authVerification from '@/mixins/authVerification';
import { setLocalStorage } from '@/utils/index';

export default {
  mixins: [authVerification],
  data() {
    return {
      whereLog: 'loginInUrl'
    };
  },
  created() {
    this.$i18n.locale = 'ZH';
    setLocalStorage('language', this.$i18n.locale);
    this.init();
  }
};
</script>
