import request from '@/utils/request';

export function adata() {
  return request({
    url: '/api/standard/adata',
    method: 'get'
  });
}
// 解密
export function descrypt(user_id_key) {
  return request({
    url: `/api/gateway/security/descrypt?dekey=${encodeURIComponent(user_id_key)}`,
    method: 'get',
  });
}

// 加密
export function encryption(user_id) {
  return request({
    url: `/api/gateway/security/scrypt?dekey=${user_id}`,
    method: 'get',
  });
}

