import Loading from '@/components/Loading';
import { auth, login, loginLog, getUserByTerrId } from '@/api/user';
import { setLocalStorage, getLocalStorage } from '@/utils/index';
import { setToken } from '@/utils/auth';
import * as crypto from 'crypto-js';

export default {
  components: {
    Loading
  },
  data() {
    return {
      loginObj: {
        userId: '',
        userName: '',
        userNameTips: '',
        userIdTips: ''
      },
      loading: false
    };
  },
  methods: {
    addLoginLog() {
      let sessionId = 's' + (new Date().getTime());
      let data = {
        user_id: this.loginObj.userId,
        login_time: new Date(),
        session: sessionId
      };
      setLocalStorage('sessionId', sessionId);
      loginLog(data).then(res => {
      });
    },
    getUserInfo() {
      this.loading = true;
      setLocalStorage('territory_id', this.$route.query.territory_id);
      auth(this.$route.query.territory_id).then(token => {
        setToken({
          'seed': token.seed,
          'app_id': token.token,
          'device_id': crypto.TripleDES.encrypt(token.login_id, crypto.enc.Utf8.parse(token.seed.substr(0, 24)), { mode: crypto.mode.ECB }).toString()
        });
        getUserByTerrId(this.$route.query.territory_id).then(res => {
          if (res.value && res.value.length > 0) {
            this.loginObj = {
              userId: res.value[0].user_id,
              userName: res.value[0].user_name
            };
            setLocalStorage('user_name', res.value[0].territory_name);
            setLocalStorage('user_id', res.value[0].user_id);
            setLocalStorage('territory_type', res.value[0].territory_type);
            this.$store.commit('GET_TERR_TYPE');
            if (res.value[0].territory_type === 'MR') {
              const stepObj = getLocalStorage('stepObj');
              if (!stepObj) {
                let stepObj = {};
                for (let i = 0; i < 9; i++) {
                  stepObj['step' + i] = 0;
                }
                setLocalStorage('stepObj', JSON.stringify(stepObj));
                this.$store.dispatch('setFIRST', 'yes');
                this.$store.dispatch('setSTEP', 0);
              }
            } else {
              this.$store.dispatch('setFIRST', 'no');
              this.$store.dispatch('setSTEP', -1);
            }
            this.$store.dispatch('settingUserData', res.value[0]);
            this.addLoginLog();
            if (this.whereLog === 'loginInUrl') {
              this.$router.push({
                path: '/hcp/list'
              });
            }
            if (this.whereLog === 'ProfileDetails') {
              this.getDetailFn();
            }
          } else {
            console.info('login fail');
          }
          this.$store.dispatch('setWaterMarket');
          this.loading = false;
        });
      });
    },
    init() {
      // this.$i18n.locale = 'ZH';
      // setLocalStorage('language', this.$i18n.locale);
      // if (this.$route.query.browser === 'oce') {
      //   setLocalStorage('login-from', this.$route.query.browser);
      //   this.$store.dispatch('setOCE', 1);
      // } else {
      //   this.$store.dispatch('setOCE', 0);
      // }
      if (!this.$route.query.territory_id && this.whereLog === 'loginInUrl') {
        this.$router.push({
          path: '/error' // For POC testing, change to '/error/' after testing
        });
      }
      this.getUserInfo();
    }
  }
};
