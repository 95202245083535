<template>
  <div class="no-data-box">
    <Loading v-if="loading" :loading="loading" />
    <span v-if="!loading" class="no-data-txt">
      {{ emptyTxt }}
    </span>
  </div>
</template>
<script>
import Loading from '@/components/Loading';

export default {
  components: {
    Loading
  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      }
    },
    emptyTxt: {
      type: String,
      default() {
        return this.$t('no_data');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/styles/var.scss';
.no-data-box {
  width: 100%;
  height: 100px;
  position: relative;
  .no-data-txt {
    font-size: $no-data-font-size;
    color: $no-data-color;
    @include position-center();
  }
}
</style>
