<template>
  <cube-loading v-if="loading" class="loading" />
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/styles/var.scss';
.loading {
  width: 100%;
  height: 100%;
  position: relative;
  ::v-deep .cube-loading-spinners {
    @include position-center();
  }
}
</style>
