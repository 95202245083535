<template>
  <div class="error-box no-fav-box">
    <p class="txt">{{ msg }}</p>
    <!-- <div class="">
      <p class="txt">{{ msg }}</p>
      <cube-button
        class="radius-btn small green"
        icon=""
        inline
        :primary="true"
        @click="nextFn"
      >{{ msg }}</cube-button>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      msg: '请从Veeva进入本系统或确认您的权限'
    };
  },
  methods: {
    nextFn() {
      console.info('跳转');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/styles/var.scss';
.no-fav-box {
  min-width: 250px;
  padding-top: 60px;
  // @include position-center();
  .txt {
    font-size: 13px;
    color: #707070;
    & + .txt {
      margin-top: 20px;
    }
  }
  .cube-btn {
    // width: 1.44rem;
    padding: 10px 40px;
    font-size: 14px;
    border-radius: 20px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
.radius-btn {
  border-radius: 20px;
}
</style>
